import styled from "styled-components";
import { WY_COLORS } from "../../../constants/app-colors";

export const StyleHomePageContainer = styled.div({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: 205,
  height: "100vh",
  overflow: "hidden",
});

export const StyledBluredImage = styled.img({
  position: "absolute",
  width: 1295,
  height: 552,
  zIndex: 2,
  borderRadius: 33,
});

export const StyledLandingPageImageContainer = styled.div({
  marginLeft: 70,
  marginBottom: 10,
});

export const StyledWhiteShadesImage = styled.img({
  width: 512,
  zIndex: 1,
  height: 580,
  display: "block",
  margin: "0 auto",
  position: "absolute",
  "@media only screen and (min-width: 1280px) and (max-width: 1440px)": {
    marginTop: 130,
  },

  // Media query for larger screens
  "@media only screen and (min-width: 1441px)": {
    marginTop: 170,
  },
});

export const StyledCenteredText = styled.div({
  position: "absolute",
  zIndex: 3,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  fontSize: "24px",
  fontWeight: "bold",
});

export const StyledAfrica = styled.div({});

export const StyledBuiltForDreamersContainer = styled.div({
  placeSelf: "end",
  display: "flex",
  flexDirection: "column",
  gap: 22,
});

export const StyledPhoneAndCallouts = styled.img({
  zIndex: 2,
  height: 1000,
  display: "block",
  margin: "0 auto",
  position: "relative",
  marginBottom: 400,
  "@media only screen and (min-width: 1280px) and (max-width: 1440px)": {
    marginTop: -435,
  },
  /**larger screens */
  "@media only screen and (min-width: 1441px)": {
    marginTop: -340,
  },
  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    marginTop: "-60%",
    width: 800,
    height: 800,
  },
});

export const StyledHeroWithBackgroungImage = styled.img({
  width: 1420,
  position: "absolute",
  top: "52%",
  left: "53%",
  transform: "translate(-50%, -50%)",

  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    width: 1000,
    top: "40%",
  },
});

export const StyledOneAppContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const StyledOneAppWording = styled.div({
  fontSize: 120,
  fontFamily: "GT Walsheim",
  position: "relative",
  zIndex: 2,
});

export const StyledGreenShadesImage = styled.img({
  position: "absolute",
  height: 400,
  right: 250,
  paddingBottom: 40,
  zIndex: 1,
});

export const StyledWeyanRevolutionSection = styled.div({
  display: "flex",
  placeContent: "center",
  margin: "0px 200px 130px 200px",
});
export const StyledJoinWeyanWording = styled.div({
  fontSize: 100,
  fontFamily: "GT Walsheim",
  whiteSpace: "nowrap",
  fontWeight: 600,
  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    fontSize: 70,
  },
});
export const StyledJoinWeyanDescription = styled.div({
  fontSize: 16,
  color: WY_COLORS.whiteWithOpacity,
  width: 352,
  paddingTop: 40,
});

export const StyledStakeholders = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: 45,
  justifyItems: "center",
  alignItems: "center",
  marginTop: 95,
  marginInline: 250,
  zIndex: 2,
  position: "relative",
  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    marginInline: 40,
  },
});

export const StyledWorldMapBlueDotsImage = styled.img({
  opacity: 0.5,
  width: 1450,
  zIndex: 1,
  height: 800,
  display: "block",
  margin: "0 auto",
  position: "absolute",

  "@media only screen and (min-width: 1280px) and (max-width: 1440px)": {
    marginLeft: 50,
    marginTop: -470,
  },

  // Media query for larger screens
  "@media only screen and (min-width: 1441px)": {
    marginLeft: 350,
    marginTop: -480,
  },
  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    marginLeft: "-50%",
    marginTop: "-70%",
  },
});

export const StyledNeedHelpImage = styled.img({
  width: "-webkit-fill-available",
  marginBottom: 120,
  display: "flex",
  justifyItems: "center",
});
