import { useEffect, useState } from "react";

export function useScreenWidth() {
  const [windowSize, setWindowSize] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const width =
        window.innerWidth < window.outerWidth
          ? window.innerWidth
          : window.outerWidth;

      setTimeout(() => {
        setWindowSize(width);
      }, 25);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
