import {
  StyledPageContainer,
  StyledSectionContainer,
} from "../../constants/styles";
import TitleSubtitleDescriptionComponent from "../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { FAQIcon } from "../../assets/images/FAQIcon";
import AppTextField from "../../components/AppTextField/AppTextField";
import { AppButton } from "../../components/AppButton/AppButton";
import AppAccordion from "../../components/AppAccordion/AppAccordion";
import { FAQ } from "../../constants/FAQ";
import {
  StyledAccordionContainer,
  StyledFAQContainer,
  StyledFAQSubTitle,
  StyledFAQTitle,
  StyledFlexColumnContainer,
} from "./FAQPage.styles";
import { useEffect, useState } from "react";

export default function FAQPage() {
  const screenWidth = useScreenWidth();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState(FAQ);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setFilteredResults(FAQ);
      return;
    }

    const results = FAQ.filter((section) =>
      section.QA.some(
        (qa) =>
          qa.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          qa.answer.toLowerCase().includes(searchQuery.toLowerCase())
      )
    ).map((section) => ({
      ...section,
      QA: section.QA.filter(
        (qa) =>
          qa.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          qa.answer.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }));
    setFilteredResults(results);
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredResults(FAQ);
    }
  }, [searchQuery]);

  return (
    <StyledPageContainer style={{ gap: 50 }}>
      <StyledSectionContainer
        style={{
          flexDirection: "column",
          gap: 40,
        }}
      >
        <TitleSubtitleDescriptionComponent
          IconComponent={FAQIcon}
          titleSpan="Frequently Asked Questions."
          smallTitle="Get Help"
          subTitle="Find everything you need to know about who we are, what we do, and what we offer. If you have any questions or need further details, don’t hesitate to contact us."
          containerStyle={{
            paddingTop: screenWidth && screenWidth <= 1025 ? "0%" : 104,
          }}
        />
        <AppTextField
          placeholder="Press / to begin search"
          styles={{
            height: 40,
            color: "white",
            maxWidth: 900,
            width: "100%",
            alignSelf: "center",
            backgroundColor: "black",
          }}
          value={searchQuery}
          onChange={setSearchQuery}
          endAdornment={
            <AppButton
              customStyles={{
                height: 32,
                width: 83,
                fontSize: 12,
                color: "white",
                border: "1px solid rgba(134, 143, 151, 0.4)",
                background: "linear-gradient(0deg, #0A0A0A, #0A0A0A), #000000",
                "&:hover": {
                  backgroundColor: "unset",
                },
              }}
              buttonLevel="secondary"
              onClick={handleSearch}
            >
              Search
            </AppButton>
          }
        />
      </StyledSectionContainer>
      <StyledFAQContainer>
        {filteredResults.length > 0 ? (
          filteredResults.map((faq) => (
            <StyledAccordionContainer>
              <AppAccordion faq title={faq.title} key={faq.title}>
                <StyledFlexColumnContainer style={{ gap: 24, maxWidth: 440 }}>
                  {faq.QA.map((question: any) => (
                    <StyledFlexColumnContainer
                      style={{
                        gap: 12,
                        alignItems: "flex-start",
                      }}
                    >
                      <StyledFAQTitle>{question?.question}</StyledFAQTitle>
                      <StyledFAQSubTitle>{question?.answer}</StyledFAQSubTitle>
                    </StyledFlexColumnContainer>
                  ))}
                </StyledFlexColumnContainer>
              </AppAccordion>
            </StyledAccordionContainer>
          ))
        ) : (
          <p>No results found</p>
        )}
      </StyledFAQContainer>
    </StyledPageContainer>
  );
}
