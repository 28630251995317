interface BusinessCardIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const BusinessCardIcon: React.FC<BusinessCardIconProps> = ({
  color = "white",
  width = 17,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4987 2H4.4987C3.02536 2 1.83203 3.18667 1.83203 4.64667V11.3533C1.83203 12.8133 3.02536 14 4.4987 14H12.4987C13.972 14 15.1654 12.8133 15.1654 11.3533V4.64667C15.1654 3.18667 13.972 2 12.4987 2ZM6.16536 4.78C7.01203 4.78 7.70536 5.47333 7.70536 6.32C7.70536 7.16667 7.01203 7.86 6.16536 7.86C5.3187 7.86 4.62536 7.16667 4.62536 6.32C4.62536 5.47333 5.3187 4.78 6.16536 4.78ZM8.74536 11.1067C8.68536 11.1733 8.59203 11.2133 8.4987 11.2133H3.83203C3.7387 11.2133 3.64536 11.1733 3.58536 11.1067C3.52536 11.04 3.49203 10.9467 3.4987 10.8533C3.61203 9.73333 4.50536 8.84667 5.62536 8.74C5.9787 8.70667 6.34536 8.70667 6.6987 8.74C7.8187 8.84667 8.7187 9.73333 8.82536 10.8533C8.8387 10.9467 8.80536 11.04 8.74536 11.1067ZM13.1654 11.1667H11.832C11.5587 11.1667 11.332 10.94 11.332 10.6667C11.332 10.3933 11.5587 10.1667 11.832 10.1667H13.1654C13.4387 10.1667 13.6654 10.3933 13.6654 10.6667C13.6654 10.94 13.4387 11.1667 13.1654 11.1667ZM13.1654 8.5H10.4987C10.2254 8.5 9.9987 8.27333 9.9987 8C9.9987 7.72667 10.2254 7.5 10.4987 7.5H13.1654C13.4387 7.5 13.6654 7.72667 13.6654 8C13.6654 8.27333 13.4387 8.5 13.1654 8.5ZM13.1654 5.83333H9.83203C9.5587 5.83333 9.33203 5.60667 9.33203 5.33333C9.33203 5.06 9.5587 4.83333 9.83203 4.83333H13.1654C13.4387 4.83333 13.6654 5.06 13.6654 5.33333C13.6654 5.60667 13.4387 5.83333 13.1654 5.83333Z"
        fill={color}
      />
    </svg>
  );
};
