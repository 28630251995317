import {
  StyledDescriptionContainer,
  StyledDescriptionSubTitle,
  StyledDescriptionTitle,
  StyledShopFeaturesDescriptionContainer,
  StyledShopFeaturesDescriptionImageContainer,
  StyledShopFeaturesHeader,
} from "./MarketPlacePage.styles";
import { ShopIcon } from "../../../assets/images/ShopIcon";
import { WY_IMAGES } from "../../../constants/images";
import { CartIcon } from "../../../assets/images/CartIcon";
import { AnalyticsIcon } from "../../../assets/images/AnalyticsIcon";
import { useState } from "react";
import { StyledGridImage } from "../../../constants/styles";

export const ShopFeature = [
  {
    title: "Store front Management",
    image: WY_IMAGES.marketPlace1,
    icon: ShopIcon,
    features: [
      {
        title: "Easy Store Management",
        description:
          "Keep your store running like a well-oiled machine with our automated systems for tracking sales and monitoring performance.",
      },
      {
        title: "Seamless Product Management",
        description:
          "Organize, showcase, and sell your products with ease. Stay above the chaos!",
      },
      {
        title: "Simplified Inventory Management",
        description:
          "Effortlessly streamline your inventory. Keep track of what's in stock and what's flying off the shelves.",
      },
    ],
  },
  {
    title: "Order Management",
    icon: CartIcon,
    image: WY_IMAGES.marketPlace3,
    features: [
      {
        title: "Order State Management",
        description:
          "From processing to delivery, you can keep tabs on every order's journey.",
      },
      {
        title: "Order Notifications",
        description:
          "Stay in sync with your customers every step of the way with instant order notifications.",
      },
      {
        title: "Order Messages",
        description:
          "Seamlessly exchange messages with customers regarding their orders, elevating their buying experience.",
      },
      {
        title: "Payment Protection",
        description:
          "Enjoy top-tier payment protection for every incoming and outgoing transaction.",
      },
    ],
  },
  {
    title: "Sales and Inventory Analytics",
    icon: AnalyticsIcon,
    image: WY_IMAGES.marketPlace2,
    features: [
      {
        title: "Sales Analytics",
        description:
          "Unlock the power of data, gain valuable insights into your sales trends, customer behavior, and make informed decisions that boost your bottom line.",
      },
      {
        title: "Revenue Analytics",
        description:
          "Take a plunge into your revenue streams data. Track your income, identify your most profitable products, and optimize your pricing strategies.",
      },
      {
        title: "Inventory Reporting",
        description:
          "Upgrade your inventory game. Access detailed reports on stock levels, turnover rates, & inventory costs to enhance your productivity.",
      },
    ],
  },
];
export default function MarketPlaceFeaturesWeb() {
  const [activeSection, setActiveSection] = useState<number | null>(0);

  const handleClick = (index: number) => {
    setActiveSection(index);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {ShopFeature.map((feature, index) => (
          <StyledShopFeaturesHeader
            isActive={activeSection === index}
            onClick={() => handleClick(index)}
          >
            <feature.icon
              color={activeSection === index ? "white" : "#8287A7"}
            />
            {feature.title}
          </StyledShopFeaturesHeader>
        ))}
      </div>

      <div>
        {ShopFeature.map((feature, index) => (
          <StyledShopFeaturesDescriptionImageContainer
            isActive={activeSection === index}
          >
            <StyledShopFeaturesDescriptionContainer>
              {feature?.features.map((section) => (
                <StyledDescriptionContainer>
                  <StyledDescriptionTitle>
                    {section.title}
                  </StyledDescriptionTitle>
                  <StyledDescriptionSubTitle>
                    {section.description}
                  </StyledDescriptionSubTitle>
                </StyledDescriptionContainer>
              ))}
            </StyledShopFeaturesDescriptionContainer>
            <StyledGridImage
              src={feature.image}
              style={{ height: "100%", borderRadius: 30 }}
            />
          </StyledShopFeaturesDescriptionImageContainer>
        ))}
      </div>
    </div>
  );
}
