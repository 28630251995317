import styled from "styled-components";

export const StyledDesignedForAfricansContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 125,
});

export const StyledDesignedForAfricansWording = styled.div({
  fontSize: 60,
  fontFamily: "GT Walsheim",
  whiteSpace: "nowrap",
  fontWeight: 500,
});

export const StyledFlagsImages = styled.img({
  height: 30,
  position: "absolute",
  left: "-30px",
});

export const StyledFlagsAndTextContainer = styled.div({
  display: "flex",
  alignItems: "center",
  position: "relative",
});

export const StyledAvatarDescriptions = styled.div({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 15,
});
