interface FAQIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const FAQIcon: React.FC<FAQIconProps> = ({
  color = "white",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.334 1.61981H4.66732C2.66732 1.61981 1.33398 2.95315 1.33398 4.95315V8.95315C1.33398 10.9531 2.66732 12.2865 4.66732 12.2865V13.7065C4.66732 14.2398 5.26065 14.5598 5.70065 14.2598L8.66732 12.2865H11.334C13.334 12.2865 14.6673 10.9531 14.6673 8.95315V4.95315C14.6673 2.95315 13.334 1.61981 11.334 1.61981ZM8.00065 9.73314C7.72065 9.73314 7.50065 9.50648 7.50065 9.23314C7.50065 8.95981 7.72065 8.73314 8.00065 8.73314C8.28065 8.73314 8.50065 8.95981 8.50065 9.23314C8.50065 9.50648 8.28065 9.73314 8.00065 9.73314ZM8.84065 6.96648C8.58065 7.13981 8.50065 7.25314 8.50065 7.43981V7.57981C8.50065 7.85314 8.27398 8.07981 8.00065 8.07981C7.72732 8.07981 7.50065 7.85314 7.50065 7.57981V7.43981C7.50065 6.66648 8.06732 6.28648 8.28065 6.13981C8.52732 5.97315 8.60732 5.85981 8.60732 5.68648C8.60732 5.35314 8.33398 5.07981 8.00065 5.07981C7.66732 5.07981 7.39398 5.35314 7.39398 5.68648C7.39398 5.95981 7.16732 6.18648 6.89398 6.18648C6.62065 6.18648 6.39398 5.95981 6.39398 5.68648C6.39398 4.79981 7.11398 4.07981 8.00065 4.07981C8.88732 4.07981 9.60732 4.79981 9.60732 5.68648C9.60732 6.44648 9.04732 6.82648 8.84065 6.96648Z"
        fill={color}
      />
    </svg>
  );
};
