import { useScreenWidth } from "../../hooks/useScreenWidth";
import { AppleStoreIcon } from "../../assets/images/AppleStoreIcon";
import { InfoIcon } from "../../assets/images/InfoIcon";
import { PlayIcon } from "../../assets/images/PlayIcon";
import { PlayStoreIcon } from "../../assets/images/PlayStoreIcon";
import { AppButton } from "../../components/AppButton/AppButton";
import DecoratedText from "../../components/DecoratedText/DecoratedText";
import TitleSubtitleDescriptionComponent from "../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import { WY_IMAGES } from "../../constants/images";
import {
  StyledColoredTextDescription,
  StyledTitle,
  StyledDescription,
  StyledHeadingContainer,
  StyledColoredText,
  StyledGridContainer,
  StyledDoubleGridContainer,
  StyledDoubleGridDescriptionContainer,
  StyledGridTitle,
  StyledGridSubTitle,
  StyledPageContainer,
  StyledSectionContainer,
} from "../../constants/styles";
import {
  StyledHeadingDescription,
  StyledLastSectionContainer,
  StyledStoresLinks,
  StyledStoresLinksContainer,
  StyledStoreLink,
} from "../AboutUsPage/AboutUsPage.styles";
import { StyledFAQTitle, StyledFAQSubTitle } from "../FAQPage/FAQPage.styles";
import { ChatIcon } from "../../assets/images/ChatIcon";
import { ThumbsUpIcon } from "../../assets/images/ThumbsUpIcon";
import { KeyIcon } from "../../assets/images/KeyIcon";
import { PrivacyIcon } from "../../assets/images/PrivacyIcon";
import { StarIcon } from "../../assets/images/StarIcon";
import { PasswordCheckIcon } from "../../assets/images/PasswordCheckIcon";
import { EyeLashIcon } from "../../assets/images/EyeLashIcon";
import { WY_COLORS } from "../../constants/app-colors";
import styled from "styled-components";

export const StyledBannerImage = styled.img({
  width: 343,
  height: 216,
  objectFit: "cover",
  borderRadius: 16,
  alignSelf: "center",
  "@media only screen and (min-width: 1200px)": {
    width: 1104,
    height: 615,
  },
});

export default function ChatPage() {
  const screenWidth = useScreenWidth();

  if (screenWidth === 0) {
    return <></>;
  }

  return (
    <StyledPageContainer style={{ gap: 100 }}>
      <StyledSectionContainer
        style={{
          flexDirection: "column",
          gap: 28,
          alignItems: "flex-start",
        }}
      >
        <TitleSubtitleDescriptionComponent
          IconComponent={ChatIcon}
          titleSpan="Conversations like"
          titleSpanTwo="you’ve never had."
          smallTitle="we.yan Digital Wallets"
          subTitle="Say what you feel, be your authentic self, and make deeper connections with our end-to-end encrypted chat technology and bespoke features."
        />
        {/* <AppButton
          buttonLevel="secondary"
          customStyles={{
            alignSelf: "flex-start",
            width: 120,
            height: 36,
            fontSize: 12,
            backgroundColor: "black",
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
          }}
        >
          <ThumbsUpIcon /> Get started
        </AppButton> */}
      </StyledSectionContainer>

      <StyledColoredTextDescription
        style={{
          marginTop: screenWidth <= 1025 ? 30 : 100,
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: screenWidth <= 1025 ? "center" : "flex-start",
          }}
        >
          {screenWidth <= 1025 ? (
            <>
              <StyledTitle>
                Messaging <DecoratedText text="ReImagined" />
              </StyledTitle>
            </>
          ) : (
            <>
              <StyledTitle>Messaging</StyledTitle>
              <StyledTitle>
                <DecoratedText text="ReImagined" />
              </StyledTitle>
            </>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <StyledDescription>
            Actions speak louder than words, so we created a way for you to do
            more than just talk. With we.yan Chat, you can attach money or gifts
            to videos, voice notes, pictures, stickers, emojis, or GIFs you
            send. Don't just say how you feel, you can show it too.
          </StyledDescription>
          {/* <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 150,
              height: 36,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <PlayIcon /> See how it works
          </AppButton> */}
        </div>
      </StyledColoredTextDescription>

      <StyledBannerImage
        src={
          screenWidth <= 1025
            ? WY_IMAGES.TransferChatImageMob
            : WY_IMAGES.TransferChatImage
        }
      />
      <StyledHeadingContainer
        style={{
          alignItems: "center",
          flexDirection: "column",
          gap: 30,
          marginTop: 100,
        }}
      >
        <StyledColoredText
          style={{
            textAlign: "center",
          }}
        >
          Privacy by design
        </StyledColoredText>
        <StyledHeadingDescription
          style={{
            textAlign: "center",
            alignItems: "center",
            textWrap: `${screenWidth > 1025 ? "nowrap" : "unset"}`,
          }}
        >
          Your security is at the forefront of our engineering, not an
          afterthought.
        </StyledHeadingDescription>
      </StyledHeadingContainer>

      <StyledGridContainer style={{ backgroundImage: "unset" }}>
        <StyledDoubleGridContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <KeyIcon />
            <StyledGridTitle>End-to-end encryption</StyledGridTitle>
            <StyledGridSubTitle>
              Your messages, media, calls are all private and encrypted. No one
              else can read or listen to them, not even we.yan.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <PrivacyIcon />
            <StyledGridTitle>Layered privacy controls</StyledGridTitle>
            <StyledGridSubTitle>
              Personalize your privacy controls - from control on access, to
              disappearing messages and images.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StarIcon />
            <StyledGridTitle>Zero tolerance policy & lifeban</StyledGridTitle>
            <StyledGridSubTitle>
              Report and block scammers. Our zero tolerance policy means
              offenders get kicked off and blacklisted.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <PasswordCheckIcon />
            <StyledGridTitle>Double the Security</StyledGridTitle>
            <StyledGridSubTitle>
              Enable two-step verification for an extra layer of protection on
              your conversations.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <EyeLashIcon />
            <StyledGridTitle>Ghost mode</StyledGridTitle>
            <StyledGridSubTitle>
              You can send delayed / scheduled messages or self-destructive
              messages which are completely deleted off the platform - your
              choice.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
      </StyledGridContainer>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledTitle>Experience New,</StyledTitle>
        <StyledTitle>
          Personalized <DecoratedText text="Chat Features" />
        </StyledTitle>
      </div>
      <StyledGridContainer style={{ backgroundImage: "unset" }}>
        <StyledDoubleGridContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Bespoke Emojis</StyledGridTitle>
            <StyledGridSubTitle>
              Express yourself with unique, hand designed emojis inspired by the
              richness and variety of Africa’s cultures, tribes and religions.
              Spice up your conversations with a continuously growing emoji
              library designed by digital artists inspired by Africa.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Sticker Stickers</StyledGridTitle>
            <StyledGridSubTitle>
              Experience complete freedom of expression. Use your personalized
              stickers or memes, to say more without using words. Even better,
              attach money to stickers or memes you send to your favorite
              people.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Incognito Mode</StyledGridTitle>
            <StyledGridSubTitle>
              Communicate only with selected individuals, keep your activities
              private. Disable read receipts, status notifications and stay in
              stealth mode as long as you like.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Invitation-Only Mode</StyledGridTitle>
            <StyledGridSubTitle>
              Manage your access, stay exclusive. Decide who can reach you, and
              screen requests to connect via invitation. Stay in complete
              control of your virtual space without any of the awkwardness.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Noise Control</StyledGridTitle>
            <StyledGridSubTitle>
              Focus on the essentials. Cut off distracting elements within the
              conversation. Minimize irrelevant messages, notifications or
              clutter from your chat interface.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Private Group Streams</StyledGridTitle>
            <StyledGridSubTitle>
              Special moments are meant to be shared with your special people.
              Live stream those important events privately to only those in your
              group and preserve those memories.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
      </StyledGridContainer>

      <StyledLastSectionContainer>
        <StyledColoredText>
          Your favorite people are <DecoratedText text="one “hello!” away" />
        </StyledColoredText>
        <div
          style={{
            color: WY_COLORS.whiteWithOpacity,
            textAlign: "center",
            width: `${screenWidth >= 1025 ? "450px" : "unset"}`,
          }}
        >
          Bridge the gap, go beyond just chatting. Stay connected to the people
          that really matter anytime, anywhere.
        </div>
        {/* {screenWidth >= 600 ? (
          <StyledStoresLinks>
            Get we.yan
            <StyledStoresLinksContainer>
              <StyledStoreLink>
                <AppleStoreIcon /> App Store
              </StyledStoreLink>
              <hr style={{ width: 23, rotate: "90deg", height: 0 }} />
              <StyledStoreLink>
                <PlayStoreIcon /> Google Play
              </StyledStoreLink>
            </StyledStoresLinksContainer>
          </StyledStoresLinks>
        ) : (
          <StyledStoresLinksContainer>
            <AppleStoreIcon />
            <PlayStoreIcon />
            Get we.yan
          </StyledStoresLinksContainer>
        )} */}
      </StyledLastSectionContainer>

      <StyledHeadingContainer
        style={{
          marginBottom: screenWidth >= 1025 ? 150 : 50,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: screenWidth >= 1025 ? 600 : "unset",
          }}
        >
          <StyledTitle>FAQs about</StyledTitle>
          <StyledTitle>we.yan chat.</StyledTitle>
        </div>
        <StyledHeadingDescription>
          <StyledFAQTitle>
            Can I send multimedia files through we.yan chat?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            Yes, you can send images, videos, and audio files through the chat
            feature.
          </StyledFAQSubTitle>
          <StyledFAQTitle>
            How does we.yan ensure my chat messages are private?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            we.yan uses end-to-end encryption and other privacy features to
            ensure the security of your messages.
          </StyledFAQSubTitle>
          <StyledFAQTitle>
            Can I report inappropriate chat behavior on we.yan?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            Yes, you can report inappropriate behavior directly through the app
            for further investigation.
          </StyledFAQSubTitle>

          {/* <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 116,
              height: 32,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <InfoIcon /> Learn more
          </AppButton> */}
        </StyledHeadingDescription>
      </StyledHeadingContainer>
    </StyledPageContainer>
  );
}
