import TitleSubtitleDescriptionComponent from "../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { WY_IMAGES } from "../../constants/images";
import { StyledLastSectionContainer } from "../AboutUsPage/AboutUsPage.styles";
import DecoratedText from "../../components/DecoratedText/DecoratedText";
import { AppButton } from "../../components/AppButton/AppButton";
import { BusinessCardIcon } from "../../assets/images/BusinessCardIcon";
import {
  StyledDoubleGridContainer,
  StyledDoubleGridDescriptionContainer,
  StyledGridContainer,
  StyledGridDescriptionContainer,
  StyledGridImage,
  StyledGridSubTitle,
  StyledGridTitle,
  StyledColoredText,
  StyledPageContainer,
  StyledSectionContainer,
  StyledSingleGridContainer,
} from "../../constants/styles";

export default function BusinessPage() {
  const screenWidth = useScreenWidth();

  return (
    <StyledPageContainer>
      <StyledSectionContainer>
        <TitleSubtitleDescriptionComponent
          IconComponent={BusinessCardIcon}
          titleSpan="Launch your"
          titleSpanTwo="hustle to new heights"
          smallTitle="we.yan Business Membership"
          subTitle="Managing and scaling your business shouldn’t be rocket science! Explore Business Plans designed to do all the heavy lifting for you."
          containerStyle={{
            paddingTop: screenWidth && screenWidth <= 1025 ? "0%" : 104,
          }}
        />
      </StyledSectionContainer>
      <StyledGridContainer>
        <StyledSingleGridContainer>
          <StyledGridDescriptionContainer
            style={{
              padding: screenWidth && screenWidth <= 1025 ? 0 : "0 50px",
            }}
          >
            <StyledGridTitle>Storefront Management</StyledGridTitle>
            <StyledGridSubTitle>
              Open a virtual store for free, manage inventory, handle orders,
              and engage with customers—all in one place.
            </StyledGridSubTitle>
          </StyledGridDescriptionContainer>
          <StyledGridImage src={WY_IMAGES.businessPageMobile} />
        </StyledSingleGridContainer>
        <StyledDoubleGridContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>Order Management</StyledGridTitle>
            <StyledGridSubTitle>
              Handle orders like a seasoned entrepreneur. Manage customer
              orders, tailor your offerings based on direct customer feedback.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer>
            <StyledGridTitle>Sales and Inventory Analytics</StyledGridTitle>
            <StyledGridSubTitle>
              Get relevant insight into customer behavior, track sales trends, &
              optimize your product offerings with automated analytics.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
        <StyledDoubleGridContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>Business Wallets</StyledGridTitle>
            <StyledGridSubTitle>
              Enjoy easy DIY wallet setups, and get direct-to-wallet payments
              from customers, with zero downtimes & hassles..
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer>
            <StyledGridTitle>Ultra-secure Transactions</StyledGridTitle>
            <StyledGridSubTitle>
              Stay protected with world-class payment infrastructure designed to
              reduce friction and boost integration with your business.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
      </StyledGridContainer>
      <StyledLastSectionContainer style={{ maxWidth: 600 }}>
        <StyledColoredText>
          Unleash <DecoratedText text="your potential" /> today!
        </StyledColoredText>
        {/* <AppButton customStyles={{ width: 155, fontSize: 16 }}>
          Get invited!
        </AppButton> */}
      </StyledLastSectionContainer>
    </StyledPageContainer>
  );
}
