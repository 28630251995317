import { useEffect, useState } from "react";
import styled from "styled-components";
import { WY_IMAGES } from "../../../constants/images";
import { WEB_OPTIONS } from "../Header.constants";
import { WY_COLORS } from "../../../constants/app-colors";
import { AppButton } from "../../AppButton/AppButton";
import { useNavigate, useLocation } from "react-router-dom";
import { PAGES_ROUTES } from "../../../constants/pages-routes";
import { WeyanWordImage } from "../../../assets/images/WeyanWordImage";
import AppMenu from "../../AppMenu/AppMenu";
import { PaymentIcon } from "../../../assets/images/PaymentIcon";
import { WalletIcon } from "../../../assets/images/WalletIcon";
import { ChatIcon } from "../../../assets/images/ChatIcon";
import { ShopIcon } from "../../../assets/images/ShopIcon";

export const StyledWebHeaderContainer = styled.header({
  display: "flex",
  alignItems: "center",
  margin: "40px 140px",
  height: 59,
  justifyContent: "center",
  flexDirection: "row",
  gap: 200,
  whiteSpace: "nowrap",
  zIndex: 9999999,
  border: "1px solid black",
  background: "black",
  borderRadius: 900,

  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    width: "max-content",
    alignSelf: "center",
    padding: 25,
  },
});

export const StyledWebOptions = styled.header({
  display: "flex",
  flexDirection: "row",
  gap: 40,
  color: WY_COLORS.whiteWithOpacityTwo,
  alignItems: "center",
});

export const StyledOption = styled.div<{
  $isSelected: boolean;
  $isClickable: boolean;
}>(({ $isSelected: isSelected, $isClickable: isClickable }) => ({
  color: isSelected
    ? WY_COLORS.primaryBrightBlue300
    : WY_COLORS.whiteWithOpacityTwo,
  cursor: isClickable ? "pointer" : "default",
  "&:hover": {
    color: isClickable
      ? WY_COLORS.primaryBrightBlue300
      : WY_COLORS.whiteWithOpacityTwo,
  },
}));

export const StyledWeyanBlackBackgroundImage = styled.img({
  width: 80,
  cursor: "pointer",
});

export const StyledMenuTitle = styled.div({
  fontWeight: 600,
});
export const StyledMenuDescription = styled.div({});

export const StyledSectionContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 10,
});
export const StyledSectionRowContainer = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  gap: 40,
});
export const StyleIconTitleContainer = styled.div({
  display: "flex",
  gap: 20,
  cursor: "pointer",
});

export const WebHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingOption = WEB_OPTIONS.find((option) =>
      currentPath.includes(PAGES_ROUTES[option.value])
    );

    if (matchingOption) {
      setSelectedOption(matchingOption.value);
    } else {
      setSelectedOption("");
    }
  }, [location.pathname]);

  const handleOptionClick = (value: string) => {
    if (value !== "features") {
      setSelectedOption(value);
      navigate(PAGES_ROUTES[value]);
    }
  };
  return (
    <StyledWebHeaderContainer>
      <div
        style={{ cursor: "pointer", display: "flex" }}
        onClick={() => navigate(PAGES_ROUTES.home)}
      >
        <WeyanWordImage width={62} height={16} />
      </div>
      <StyledWebOptions>
        <AppMenu menuStyles={{ width: 740 }} title="Features">
          <div
            style={{
              padding: 20,
              display: "flex",
            }}
          >
            <StyledSectionRowContainer>
              <StyledSectionContainer>
                <StyleIconTitleContainer
                  onClick={() => {
                    navigate(PAGES_ROUTES.features.payments);
                  }}
                >
                  <div>
                    <PaymentIcon width={25} height={25} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <StyledMenuTitle
                      onClick={() => navigate(PAGES_ROUTES.features.payments)}
                    >
                      Payments
                    </StyledMenuTitle>
                    <StyledMenuDescription>
                      Safe, easy, fun transactions with integrated social
                      enablements
                    </StyledMenuDescription>
                  </div>
                </StyleIconTitleContainer>
              </StyledSectionContainer>

              <StyledSectionContainer>
                <StyleIconTitleContainer
                  onClick={() => navigate(PAGES_ROUTES.features.wallets)}
                >
                  <div>
                    <WalletIcon width={25} height={25} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <StyledMenuTitle>Wallets</StyledMenuTitle>
                    <StyledMenuDescription>
                      Hassle-free, easy-to-set up creative way to manage money
                    </StyledMenuDescription>
                  </div>
                </StyleIconTitleContainer>
              </StyledSectionContainer>
            </StyledSectionRowContainer>

            <div
              style={{
                borderLeft: `1px solid ${WY_COLORS.whiteWithOpacityTwo}`,
                margin: "0 20px", // Adjust spacing if needed
                // height: "100%", // Ensure the line spans the full height of the section container
              }}
            />

            <StyledSectionRowContainer>
              <StyledSectionContainer>
                <StyleIconTitleContainer
                  onClick={() => navigate(PAGES_ROUTES.features.chats)}
                >
                  <div>
                    <ChatIcon width={25} height={25} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <StyledMenuTitle>Chats</StyledMenuTitle>
                    <StyledMenuDescription>
                      End-to-end double encrypted technology & bespoke
                      abilities.
                    </StyledMenuDescription>
                  </div>
                </StyleIconTitleContainer>
              </StyledSectionContainer>

              <StyledSectionContainer>
                <StyleIconTitleContainer
                  onClick={() => navigate(PAGES_ROUTES.features.marketPlace)}
                >
                  <div>
                    <ShopIcon
                      color="rgba(255, 88, 36, 1)"
                      width={25}
                      height={25}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <StyledMenuTitle>Marketplace</StyledMenuTitle>
                    <StyledMenuDescription>
                      One-stop shop with innovative chat & payment technologies{" "}
                    </StyledMenuDescription>
                  </div>
                </StyleIconTitleContainer>
              </StyledSectionContainer>
            </StyledSectionRowContainer>
          </div>
        </AppMenu>

        {WEB_OPTIONS.map((option) => (
          <StyledOption
            key={option.value}
            $isSelected={option.value === selectedOption}
            $isClickable={option.value !== "features"}
            onClick={() => handleOptionClick(option.value)}
          >
            {option.title}
          </StyledOption>
        ))}
      </StyledWebOptions>
      {/* <AppButton
        customStyles={{
          height: 43,
          width: 155,
          fontSize: 16,
          fontWeight: 600,
        }}
        buttonLevel="primary"
      >
        Get invited!
      </AppButton> */}
    </StyledWebHeaderContainer>
  );
};
