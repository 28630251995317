interface AnalyticsIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const AnalyticsIcon: React.FC<AnalyticsIconProps> = ({
  color = "white",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.794 1.33334H5.20732C2.78065 1.33334 1.33398 2.78 1.33398 5.20667V10.7867C1.33398 13.22 2.78065 14.6667 5.20732 14.6667H10.7873C13.214 14.6667 14.6607 13.22 14.6607 10.7933V5.20667C14.6673 2.78 13.2207 1.33334 10.794 1.33334ZM5.08732 12.1C5.08732 12.3733 4.86065 12.6 4.58732 12.6C4.31398 12.6 4.08732 12.3733 4.08732 12.1V10.72C4.08732 10.4467 4.31398 10.22 4.58732 10.22C4.86065 10.22 5.08732 10.4467 5.08732 10.72V12.1ZM8.50065 12.1C8.50065 12.3733 8.27398 12.6 8.00065 12.6C7.72732 12.6 7.50065 12.3733 7.50065 12.1V9.33334C7.50065 9.06 7.72732 8.83334 8.00065 8.83334C8.27398 8.83334 8.50065 9.06 8.50065 9.33334V12.1ZM11.914 12.1C11.914 12.3733 11.6873 12.6 11.414 12.6C11.1407 12.6 10.914 12.3733 10.914 12.1V7.95334C10.914 7.68 11.1407 7.45334 11.414 7.45334C11.6873 7.45334 11.914 7.68 11.914 7.95334V12.1ZM11.914 5.84667C11.914 6.12 11.6873 6.34667 11.414 6.34667C11.1407 6.34667 10.914 6.12 10.914 5.84667V5.2C9.21398 6.94667 7.08732 8.18 4.70732 8.77334C4.66732 8.78667 4.62732 8.78667 4.58732 8.78667C4.36065 8.78667 4.16065 8.63334 4.10065 8.40667C4.03398 8.14 4.19398 7.86667 4.46732 7.8C6.71398 7.24 8.71398 6.06 10.3007 4.39334H9.46732C9.19398 4.39334 8.96732 4.16667 8.96732 3.89334C8.96732 3.62 9.19398 3.39334 9.46732 3.39334H11.4206C11.4473 3.39334 11.4673 3.40667 11.494 3.40667C11.5273 3.41334 11.5606 3.41334 11.594 3.42667C11.6273 3.44 11.654 3.46 11.6873 3.48C11.7073 3.49334 11.7273 3.5 11.7473 3.51334C11.754 3.52 11.754 3.52667 11.7606 3.52667C11.7873 3.55334 11.8073 3.58 11.8273 3.60667C11.8473 3.63334 11.8673 3.65334 11.874 3.68C11.8873 3.70667 11.8873 3.73334 11.894 3.76667C11.9007 3.8 11.914 3.83334 11.914 3.87334C11.914 3.88 11.9206 3.88667 11.9206 3.89334V5.84667H11.914Z"
        fill={color}
      />
    </svg>
  );
};
