import { ReactElement, ReactNode } from "react";
import styled, { CSSObject } from "styled-components";
import { Button } from "@mui/material";
import { WY_COLORS } from "../../constants/app-colors";

export interface AppButtonProps {
  isDisabled?: boolean;
  onClick?: (e: any) => any;
  children: ReactNode;
  isLoading?: boolean;
  customStyles?: CSSObject;
  buttonLevel?: "primary" | "secondary" | "tertiary";
  type?: "submit" | "reset";
  loadingSpinnerWidth?: number;
}

const SECONDARY_BUTTON_STYLES: CSSObject = {
  color: WY_COLORS.nuetralGrey300,
  background: WY_COLORS.primaryBlack400,
  border: `1px solid ${WY_COLORS.primaryBlack300}`,
  "&:hover": {
    background: WY_COLORS.primaryBlack400,
    border: `1px solid ${WY_COLORS.white}`,
  },
};

const TERTIARY_BUTTON_STYLES: CSSObject = {
  color: WY_COLORS.white,
  background: WY_COLORS.accentOrange300,
  border: `1px solid ${WY_COLORS.accentOrange300}`,
  "&:hover": {
    background: WY_COLORS.accentOrange300,
    border: `1px solid ${WY_COLORS.white}`,
  },
};

const StyledButton = styled(Button)<{ $customStyles: CSSObject }>(
  ({ $customStyles }) => ({
    "&&": {
      background: WY_COLORS.primaryBrightBlue400,
      height: 48,
      borderRadius: 24,
      width: "100%",
      border: `1px solid ${WY_COLORS.primaryBrightBlue400}`,
      color: WY_COLORS.primaryBlack700,
      textTransform: "none",
      fontWeight: 600,
      "&:active": {
        transform: "scale(0.994)",
      },
      "&:hover": {
        background: WY_COLORS.primaryBrightBlue400,
        border: `1px solid ${WY_COLORS.white}`,
      },
      ...$customStyles,
    },
  })
);

export function AppButton(props: AppButtonProps): ReactElement {
  return (
    <div style={{ borderRadius: 24 }}>
      <StyledButton
        onClick={
          props.isDisabled || props.isLoading ? undefined : props.onClick
        }
        tabIndex={0}
        $customStyles={{
          ...(props.isDisabled && { opacity: 0.5 }),
          ...((props.buttonLevel === "secondary" || props?.isLoading) && {
            ...SECONDARY_BUTTON_STYLES,
          }),
          ...(props.buttonLevel === "tertiary" && {
            ...TERTIARY_BUTTON_STYLES,
          }),
          ...props.customStyles,
        }}
        type={props.type}
        disabled={props.isDisabled || props.isLoading}
      >
        {props.children}
      </StyledButton>
    </div>
  );
}
