interface ChatIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const ChatIcon: React.FC<ChatIconProps> = ({
  color = "#3CE5FF",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9803 0.666626H12.0203C11.1737 0.666626 10.667 1.17329 10.667 2.01996V3.97996C10.667 4.82663 11.1737 5.33329 12.0203 5.33329H13.9803C14.827 5.33329 15.3337 4.82663 15.3337 3.97996V2.01996C15.3337 1.17329 14.827 0.666626 13.9803 0.666626ZM14.407 3.11996C14.1603 3.90663 13.3003 4.33329 13.0003 4.33329C12.7003 4.33329 11.847 3.91996 11.5937 3.11996C11.5537 2.99996 11.5337 2.86663 11.5337 2.73996C11.5337 2.30663 11.7537 1.85996 12.2137 1.71329C12.487 1.62663 12.7803 1.67329 12.987 1.84663C13.2003 1.67996 13.4937 1.62663 13.7737 1.71329C14.387 1.90663 14.5803 2.59329 14.407 3.11996Z"
        fill={color}
      />
      <path
        d="M12.0197 6.33337C10.7197 6.33337 9.66634 5.28004 9.66634 3.98004V2.00004C9.66634 1.63337 9.36634 1.33337 8.99967 1.33337H4.66634C2.82634 1.33337 1.33301 2.82004 1.33301 4.65337V9.30004C1.33301 11.14 2.82634 12.6334 4.66634 12.6334H5.66634C5.85301 12.6334 6.09301 12.7534 6.19967 12.9L7.19967 14.2267C7.63967 14.8134 8.35967 14.8134 8.79967 14.2267L9.79967 12.9C9.92634 12.7334 10.1263 12.6334 10.333 12.6334H11.3397C13.1797 12.6334 14.6663 11.1467 14.6663 9.30671V7.00004C14.6663 6.63337 14.3663 6.33337 13.9997 6.33337H12.0197ZM5.33301 8.00004C4.95967 8.00004 4.66634 7.70004 4.66634 7.33337C4.66634 6.96671 4.95967 6.66671 5.33301 6.66671C5.69967 6.66671 5.99967 6.96671 5.99967 7.33337C5.99967 7.70004 5.70634 8.00004 5.33301 8.00004ZM7.99967 8.00004C7.62634 8.00004 7.33301 7.70004 7.33301 7.33337C7.33301 6.96671 7.62634 6.66671 7.99967 6.66671C8.36634 6.66671 8.66634 6.96671 8.66634 7.33337C8.66634 7.70004 8.37301 8.00004 7.99967 8.00004Z"
        fill={color}
      />
    </svg>
  );
};
