import React from "react";
import { WY_IMAGES } from "../../constants/images";
import { TwoHeartsIcon } from "../../assets/images/TwoHeartsIcon";
import TitleSubtitleDescriptionComponent from "../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import {
  StyledHeading,
  StyledHeadingDescription,
  StyledLastSectionContainer,
  StyledMapContainer,
  StyledMemberCard,
  StyledMemberName,
  StyledMemberTitle,
  StyledSection,
  StyledSectionContainer,
  StyledSocialContainer,
  StyledStoreLink,
  StyledStoresLinks,
  StyledStoresLinksContainer,
  StyledTeamContainer,
  StyledTextContainer,
} from "./AboutUsPage.styles";
import { XIcon } from "../../assets/images/XIcon";
import { LinkedInIcon } from "../../assets/images/LinkedInIcon";
import { GitHubIcon } from "../../assets/images/GitHubIcon";
import { AppleStoreIcon } from "../../assets/images/AppleStoreIcon";
import { PlayStoreIcon } from "../../assets/images/PlayStoreIcon";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import DecoratedText from "../../components/DecoratedText/DecoratedText";
import {
  StyledColoredText,
  StyledDoubleGridDescriptionContainer,
  StyledGridSubTitle,
  StyledHeadingContainer,
  StyledPageContainer,
  StyledTitle,
} from "../../constants/styles";
import { AppAvatar } from "../../components/AppAvatar/AppAvatar";
import { stakeHolders } from "../HomePage/HomePage.constants";

export default function AboutUsPage() {
  const screenWidth = useScreenWidth();

  // const leads = [
  //   {
  //     name: "Tamer Bahgat",
  //     position: "CEO",
  //     flag: WY_IMAGES.egyptFlag,
  //     avatar: WY_IMAGES.stakeHolders.TamerBahgat,
  //   },
  //   {
  //     name: "Tarik Helmy",
  //     position: "CPO",
  //     flag: WY_IMAGES.egyptFlag,
  //     avatar: WY_IMAGES.stakeHolders.TarikHelmy,
  //   },
  //   {
  //     name: "Mazen Ebeid",
  //     position: "CTO",
  //     flag: WY_IMAGES.egyptFlag,
  //     avatar: WY_IMAGES.stakeHolders.MazenEbeid,
  //   },
  // ];

  return (
    <StyledPageContainer>
      <StyledMapContainer>
        <TitleSubtitleDescriptionComponent
          IconComponent={TwoHeartsIcon}
          titleSpan="Leading the charge"
          titleSpanTwo={
            <div>
              on <DecoratedText text="Africa’s Frontier." />
            </div>
          }
          smallTitle="About we.yan"
          subTitle="we.yan is more than just a payments platform."
          containerStyle={{
            marginTop: screenWidth && screenWidth <= 600 ? 0 : -550,
          }}
        />
      </StyledMapContainer>

      <StyledTextContainer>
        Designed by Africans for Africans, and inspired by our continent's rich
        heritage. we.yan is more than just a payments platform. It is a
        revolutionary leap forward showcasing the incredible results of African
        innovation and collaboration. we.yan serves as a testament to our
        potential as a people and a beacon for what we can achieve when we come
        together.
      </StyledTextContainer>

      <StyledDoubleGridDescriptionContainer
        style={{
          flexDirection: screenWidth && screenWidth >= 1025 ? "row" : "column",
          justifyContent: "space-between",
          borderTop: "unset",
          borderBottom: "unset",
        }}
      >
        <div>
          <StyledTitle style={{ lineHeight: "unset" }}>Pushing</StyledTitle>
          <StyledTitle>
            <DecoratedText text="innovative" />
          </StyledTitle>
          <StyledTitle>
            <DecoratedText text="boundaries" />
          </StyledTitle>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "flex-start",
            maxWidth: screenWidth && screenWidth >= 1025 ? 500 : "100%",
          }}
        >
          <StyledGridSubTitle
            style={{ display: "flex", flexDirection: "column", gap: 20 }}
          >
            <div>
              At we.yan, we are on a mission to shake up the way people
              transact, shop and communicate virtually, across Africa and the
              world.
            </div>
            <div>
              We celebrate the diversity of Africa, which is not just one place,
              but an incredible collection of cultures, religions, and
              histories.
            </div>
            <div>
              Our vision is to bring this sophistication and beauty right to
              your fingertips, regardless of where you are located in the world
              - from Lagos to Nairobi, Cairo to Cape Town, Abidjan to Paris or
              London to New York.
            </div>
            <div>
              We aim to empower YOU to do more with your money by providing a
              platform that is as artistic as it is functional, enabling you to
              save, spend, invest or gift without being held back by borders or
              barriers. The narrative has changed.
            </div>
          </StyledGridSubTitle>
          {/* <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 116,
              height: 32,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <InfoIcon /> Learn more
          </AppButton> */}
        </div>
      </StyledDoubleGridDescriptionContainer>
      <StyledSectionContainer>
        <StyledSection>
          We're not in it for a sprint – we're here for the long haul, and we're
          excited to have you along for the ride!🚀
        </StyledSection>
        <StyledTeamContainer>
          {stakeHolders.map((lead, index) => {
            return (
              <StyledMemberCard>
                <AppAvatar
                  styles={{ width: 90, height: 90 }}
                  key={index}
                  imageSrc={lead.avatar}
                  name={lead.name}
                />
                <StyledMemberName>{lead.name}</StyledMemberName>
                <StyledMemberTitle>{lead.position}</StyledMemberTitle>
                {/* <StyledSocialContainer>
                  <XIcon />
                  <LinkedInIcon />
                  <GitHubIcon />
                </StyledSocialContainer> */}
              </StyledMemberCard>
            );
          })}
        </StyledTeamContainer>
      </StyledSectionContainer>
      <StyledLastSectionContainer>
        <StyledColoredText>
          The future is just <DecoratedText text="one download" /> away!
        </StyledColoredText>
        {/* {screenWidth && screenWidth >= 600 ? (
          <StyledStoresLinks>
            Get we.yan
            <StyledStoresLinksContainer>
              <StyledStoreLink>
                <AppleStoreIcon /> App Store
              </StyledStoreLink>
              <hr style={{ width: 23, rotate: "90deg", height: 0 }} />
              <StyledStoreLink>
                <PlayStoreIcon /> Google Play
              </StyledStoreLink>
            </StyledStoresLinksContainer>
          </StyledStoresLinks>
        ) : (
          <StyledStoresLinksContainer>
            <AppleStoreIcon />
            <PlayStoreIcon />
            Get we.yan
          </StyledStoresLinksContainer>
        )} */}
      </StyledLastSectionContainer>
    </StyledPageContainer>
  );
}
