export const TITLES_SUBTITLES = [
  // {
  //   title: "Social Media",
  //   subTitles: ["LinkedIn", "Twitter", "Instagram", "TikTok"],
  // },
  {
    title: "Explore",
    subTitles: [
      {
        key: "/features/payments",
        value: "Payments",
      },
      {
        key: "/features/chats",
        value: "Chats",
      },
      {
        key: "/features/marketPlace",
        value: "Marketplace",
      },
      {
        key: "/features/wallets",
        value: "Wallet",
      },
    ],
  },
  {
    title: "Company",
    subTitles: [
      {
        key: "/about-us",
        value: "About Us",
      },
    ],
  },
  // {
  //   title: "Legal",
  //   subTitles: ["Terms and Conditions", "Privacy Policy", "Cookie Policy"],
  // },
];
