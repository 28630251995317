import styled from "styled-components";
import { WY_IMAGES } from "../../constants/images";
import { WY_COLORS } from "../../constants/app-colors";

export const StyledMapContainer = styled.div`
  background-image: url(${WY_IMAGES.map});
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width: 600px) {
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;

export const StyledHeading = styled.div`
  font-size: 24px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 500;
  line-height: 27px;
  gap: 2px;
  max-width: 200px;
  font-family: GT Walsheim;
  @media only screen and (min-width: 600px) {
    font-size: 56px;
    line-height: 63px;
    max-width: 300px;
  }
`;

export const StyledHeadingDescription = styled.div({
  maxWidth: 450,
  width: "100%",
  textAlign: "left",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "22px",
  color: WY_COLORS.whiteWithOpacity,
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: 16,
});

export const StyledTextContainer = styled.div`
  text-align: left;
  max-width: 700px;
  width: 100%;
  align-self: center;
  font-size: 18px;

  font-weight: 500;
  line-height: 27px;
  color: ${WY_COLORS.whiteWithOpacity};

  @media only screen and (min-width: 600px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const StyledSectionContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  gap: 80,
});
export const StyledSection = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: ${WY_COLORS.whiteWithOpacity};
  max-width: 700px;
  width: 100%;
  @media only screen and (min-width: 600px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const StyledTeamContainer = styled.div({
  backgroundImage: `url(${WY_IMAGES.lightSource})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: 30,
  border: "1px solid #ffffff0d",
  padding: "36px",
  borderRadius: "16px",

  "@media only screen and (min-width: 1025px)": {
    width: "70%",
    gap: "52px",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    alignItems: "center",
    marginInline: 250,
    zIndex: 2,
  },
});

export const StyledMemberCard = styled.div({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 10,
  // width: "30%",
  justifyContent: "center",
});
export const StyledMemberName = styled.div({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  color: "#FFFFFF",
});
export const StyledMemberTitle = styled.div({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  color: "#9099A1",
});
export const StyledSocialContainer = styled.div({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: 16,
  padding: 8,
  cursor: "pointer",
  justifyContent: "center",
});

export const StyledLastSectionContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: 28,
  marginBottom: 150,
  alignSelf: "center",
});

export const StyledStoresLinks = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  gap: 16,
});
export const StyledStoresLinksContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  border: "1px solid #868F9766",
  padding: 12,
  height: 40,
  gap: 10,
  borderRadius: 28,
});
export const StyledStoreLink = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
});
