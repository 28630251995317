import React from "react";
import {
  StyledColoredText,
  StyledColoredTextDescription,
  StyledDescription,
  StyledDoubleGridContainer,
  StyledDoubleGridDescriptionContainer,
  StyledGridContainer,
  StyledGridSubTitle,
  StyledGridTitle,
  StyledHeadingContainer,
  StyledPageContainer,
  StyledSectionContainer,
  StyledTitle,
} from "../../../constants/styles";
import TitleSubtitleDescriptionComponent from "../../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import { AppButton } from "../../../components/AppButton/AppButton";
import { WalletIcon } from "../../../assets/images/WalletIcon";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import { PlayIcon } from "../../../assets/images/PlayIcon";
import { WY_IMAGES } from "../../../constants/images";

import {
  StyledBannerImage,
  StyledDescriptionSubTitle,
  StyledDescriptionTitle,
  StyledWalletDescription,
  StyledWalletDescriptionContainer,
  StyledWalletImage,
  StyledWalletImgDescription,
} from "./WalletPage.styles";
import { ClosedWalletIcon } from "../../../assets/images/ClosedWalletIcon";
import DecoratedText from "../../../components/DecoratedText/DecoratedText";
import {
  StyledHeadingDescription,
  StyledLastSectionContainer,
  StyledStoreLink,
  StyledStoresLinks,
  StyledStoresLinksContainer,
} from "../../AboutUsPage/AboutUsPage.styles";
import { AppleStoreIcon } from "../../../assets/images/AppleStoreIcon";
import { PlayStoreIcon } from "../../../assets/images/PlayStoreIcon";
import {
  StyledFAQSubTitle,
  StyledFAQTitle,
} from "../../FAQPage/FAQPage.styles";
import { InfoIcon } from "../../../assets/images/InfoIcon";

export default function WalletPage() {
  const screenWidth = useScreenWidth();

  return (
    <StyledPageContainer>
      <StyledSectionContainer
        style={{
          flexDirection: "column",
          gap: 28,
          alignItems: "flex-start",
        }}
      >
        <TitleSubtitleDescriptionComponent
          IconComponent={WalletIcon}
          titleSpan="Experience true freedom"
          smallTitle="we.yan Digital Wallets"
          subTitle="Enjoy a hassle-free setup, seamless top-ups and transfers, and more secure and creative ways to manage your money."
          containerStyle={{
            paddingTop: screenWidth && screenWidth <= 1025 ? "0%" : 104,
          }}
        />
        {/* <AppButton
          buttonLevel="secondary"
          customStyles={{
            alignSelf: "flex-start",
            width: 210,
            height: 36,
            fontSize: 12,
            backgroundColor: "black",
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
          }}
        >
          <PlayIcon /> How to set-up your wallet
        </AppButton> */}
      </StyledSectionContainer>
      <StyledWalletImgDescription>
        <StyledBannerImage
          src={
            screenWidth && screenWidth <= 1025
              ? WY_IMAGES.walletPageImgMob
              : WY_IMAGES.walletPageImg
          }
        />
        <StyledWalletDescriptionContainer>
          <StyledWalletDescription>
            <WalletIcon color="#8287A7" />
            <StyledDescriptionTitle>Local Wallet</StyledDescriptionTitle>
            <StyledDescriptionSubTitle>
              Perfect for daily use, our local wallets allow you to manage funds
              in your domestic currency on the go. Set up your wallet in minutes
              and start sending, receiving, and shopping right from your mobile
              device.
            </StyledDescriptionSubTitle>
          </StyledWalletDescription>
          <StyledWalletDescription>
            <ClosedWalletIcon />
            <StyledDescriptionTitle>
              International Wallets
            </StyledDescriptionTitle>
            <StyledDescriptionSubTitle>
              Go borderless with your transactions. Our international wallets
              make handling foreign currencies stress-free. With multi-currency
              features, you can seamlessly transact globally from your pocket,
              cutting through unnecessary bottlenecks.
            </StyledDescriptionSubTitle>
          </StyledWalletDescription>
        </StyledWalletDescriptionContainer>
      </StyledWalletImgDescription>
      <StyledColoredTextDescription
        style={{
          alignItems:
            screenWidth && screenWidth <= 1025 ? "center" : "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems:
              screenWidth && screenWidth <= 1025 ? "center" : "flex-start",
          }}
        >
          <StyledTitle>Security first,</StyledTitle>
          <StyledTitle>
            <DecoratedText text="Always" />
          </StyledTitle>
        </div>
        <StyledDescription
          style={{
            textAlign: screenWidth && screenWidth <= 1025 ? "center" : "left",
          }}
        >
          Your security is our top priority. we.yan wallets are built with
          cutting-edge technology to protect your money and personal
          information. Real-time monitoring and immediate alerts on suspicious
          activity ensure that we stay on top of any threats from scammers, to
          phishers and hackers. Enjoy 24/7 guaranteed safety.
        </StyledDescription>
      </StyledColoredTextDescription>

      <StyledColoredTextDescription
        style={{
          alignItems:
            screenWidth && screenWidth <= 1025 ? "center" : "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems:
              screenWidth && screenWidth <= 1025 ? "center" : "flex-start",
          }}
        >
          <StyledTitle>Your wallet, just </StyledTitle>
          <StyledTitle>
            <DecoratedText text="how you like it" />
          </StyledTitle>
        </div>
        <StyledDescription
          style={{
            textAlign: screenWidth && screenWidth <= 1025 ? "center" : "left",
          }}
        >
          Tailor your we.yan wallet to suit your lifestyle. Set up budgets,
          track your spending, and monitor your financial health with intuitive
          tools that make money management a breeze. Plus, you can adjust
          settings and preferences to match your financial habits.
        </StyledDescription>
      </StyledColoredTextDescription>
      <StyledColoredTextDescription
        style={{
          alignItems:
            screenWidth && screenWidth <= 1025 ? "center" : "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems:
              screenWidth && screenWidth <= 1025 ? "center" : "flex-start",
          }}
        >
          <StyledTitle>Shop and pay</StyledTitle>
          <StyledTitle>
            <DecoratedText text=" on the go" />
          </StyledTitle>
        </div>
        <StyledDescription
          style={{
            textAlign: screenWidth && screenWidth <= 1025 ? "center" : "left",
          }}
        >
          Whether you’re buying from a local artisan or from your favorite food
          Merchant, we.yan wallets make transactions seamless and
          straightforward—just a few taps and you’re done! Easy, seamless,
          secure payments for all your shopping needs.
        </StyledDescription>
      </StyledColoredTextDescription>

      <StyledHeadingContainer
        style={{
          alignItems: "center",
          flexDirection: "column",
          gap: 30,
        }}
      >
        <StyledColoredText
          style={{
            textAlign: "center",
          }}
        >
          Get a <DecoratedText text=" we.yan" />
          card Stay in control.
        </StyledColoredText>
        <StyledHeadingDescription style={{ textAlign: "center" }}>
          Whether you’re buying from a local artisan or from your favorite food
          Merchant, we.yan wallets make transactions seamless and
          straightforward—just a few taps and you’re done! Easy, seamless,
          secure payments for all your shopping needs.
        </StyledHeadingDescription>
        <StyledWalletImage src={WY_IMAGES.weyanWallet} />
      </StyledHeadingContainer>
      <StyledGridContainer
        style={{
          backgroundImage: "none",
          marginTop: screenWidth && screenWidth >= 1025 ? -250 : "unset",
          backgroundColor: "black",
        }}
      >
        <StyledDoubleGridContainer style={{ width: "100%" }}>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth && screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Contactless Convenience</StyledGridTitle>
            <StyledGridSubTitle>
              Pay swiftly without physical contact, ensuring fast and secure
              transactions.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderWidth: screenWidth && screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Worldwide Acceptance</StyledGridTitle>
            <StyledGridSubTitle>
              Use your debit card wherever Visa or Mastercard is accepted, both
              locally and internationally.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth && screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Real-Time Alerts</StyledGridTitle>
            <StyledGridSubTitle>
              Stay updated with instant notifications for every transaction,
              keeping you in control of your finances.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderWidth: screenWidth && screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Worldwide Acceptance</StyledGridTitle>
            <StyledGridSubTitle>
              Easily track spending and manage your budget with intuitive in-app
              tools that categorize expenses.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth && screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>ATM Access</StyledGridTitle>
            <StyledGridSubTitle>
              Enjoy cash withdrawals directly from your we.yan wallet through a
              wide network of ATMs.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderWidth: screenWidth && screenWidth <= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Virtual Card Option</StyledGridTitle>
            <StyledGridSubTitle>
              Generate a virtual debit card for secure online shopping,
              minimizing the risk of fraud.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
              borderWidth: screenWidth && screenWidth >= 1025 ? 0 : 0,
            }}
          >
            <StyledGridTitle>Personalized Designs</StyledGridTitle>
            <StyledGridSubTitle>
              Receive a customized debit card from our range of unique designs
              that perfectly reflect your style.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledLastSectionContainer
            style={{ margin: "80px 0", gridColumn: "1 / -1" }}
          >
            <StyledColoredText>
              More than just a card, <DecoratedText text="it’s a flex!" />
            </StyledColoredText>
            {/* {screenWidth && screenWidth >= 600 ? (
              <StyledStoresLinks>
                Get we.yan
                <StyledStoresLinksContainer>
                  <StyledStoreLink>
                    <AppleStoreIcon /> App Store
                  </StyledStoreLink>
                  <hr style={{ width: 23, rotate: "90deg", height: 0 }} />
                  <StyledStoreLink>
                    <PlayStoreIcon /> Google Play
                  </StyledStoreLink>
                </StyledStoresLinksContainer>
              </StyledStoresLinks>
            ) : (
              <StyledStoresLinksContainer>
                <AppleStoreIcon />
                <PlayStoreIcon />
                Get we.yan
              </StyledStoresLinksContainer>
            )} */}
          </StyledLastSectionContainer>
        </StyledDoubleGridContainer>
      </StyledGridContainer>
      <StyledHeadingContainer
        style={{
          alignItems: "flex-start",
          marginBottom: screenWidth && screenWidth >= 1025 ? 150 : 50,
        }}
      >
        <StyledColoredText style={{ textAlign: "left" }}>
          FAQs about we.yan wallets.
        </StyledColoredText>
        <StyledHeadingDescription>
          <StyledFAQTitle>
            What types of wallets are available on we.yan?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            we.yan offers personal, family, household, merchant, and
            multi-currency wallets.
          </StyledFAQSubTitle>
          <StyledFAQTitle>How do I open a wallet and top it up?</StyledFAQTitle>
          <StyledFAQSubTitle>
            You can open a wallet by passing basic security checks, and top it
            up using debit cards, bank transfers, or cash points.
          </StyledFAQSubTitle>
          <StyledFAQTitle>
            Are there any subscription fees for wallets on we.yan?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            Some wallet types require a subscription, such as premium wallets
            offering additional features.
          </StyledFAQSubTitle>
          <StyledFAQTitle>How can I get a we.yan card?</StyledFAQTitle>
          <StyledFAQSubTitle>
            You can obtain a We.yan card by applying through our app or website.
            Simply follow the steps to fill out an application, and once
            approved, your physical or virtual card will be issued.
          </StyledFAQSubTitle>
          <StyledFAQTitle>Is the we.yan card safe to use? </StyledFAQTitle>
          <StyledFAQSubTitle>
            Yes, the We.yan card ensures secure transactions with features like
            real-time alerts, virtual card options for online security, and
            worldwide acceptance under Visa or Mastercard networks.
          </StyledFAQSubTitle>

          {/* <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 116,
              height: 32,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <InfoIcon /> Learn more
          </AppButton> */}
        </StyledHeadingDescription>
      </StyledHeadingContainer>
    </StyledPageContainer>
  );
}
