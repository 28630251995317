interface WalletIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const WalletIcon: React.FC<WalletIconProps> = ({
  color = "#BFF100",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.89935 2.63337V5.1667H8.89935V2.63337C8.89935 2.45337 8.73935 2.3667 8.63268 2.3667C8.59935 2.3667 8.56602 2.37337 8.53268 2.3867L3.24602 4.38003C2.89268 4.51337 2.66602 4.8467 2.66602 5.2267V5.67337C2.05935 6.1267 1.66602 6.85337 1.66602 7.67337V5.2267C1.66602 4.43337 2.15268 3.7267 2.89268 3.4467L8.18602 1.4467C8.33268 1.39337 8.48602 1.3667 8.63268 1.3667C9.29935 1.3667 9.89935 1.9067 9.89935 2.63337Z"
        fill={color}
      />
      <path
        d="M14.3331 9.66683V10.3335C14.3331 10.5135 14.1931 10.6602 14.0065 10.6668H13.0331C12.6798 10.6668 12.3598 10.4068 12.3331 10.0602C12.3131 9.8535 12.3931 9.66016 12.5265 9.52683C12.6465 9.40016 12.8131 9.3335 12.9931 9.3335H13.9998C14.1931 9.34016 14.3331 9.48683 14.3331 9.66683Z"
        fill={color}
      />
      <path
        d="M12.986 8.63317H13.666C14.0327 8.63317 14.3327 8.33317 14.3327 7.9665V7.67317C14.3327 6.29317 13.206 5.1665 11.826 5.1665H4.17268C3.60602 5.1665 3.08602 5.35317 2.66602 5.67317C2.05935 6.1265 1.66602 6.85317 1.66602 7.67317V12.1598C1.66602 13.5398 2.79268 14.6665 4.17268 14.6665H11.826C13.206 14.6665 14.3327 13.5398 14.3327 12.1598V12.0332C14.3327 11.6665 14.0327 11.3665 13.666 11.3665H13.086C12.446 11.3665 11.8327 10.9732 11.666 10.3532C11.526 9.8465 11.6927 9.35984 12.026 9.03317C12.2727 8.77984 12.6127 8.63317 12.986 8.63317ZM9.33268 8.49984H4.66602C4.39268 8.49984 4.16602 8.27317 4.16602 7.99984C4.16602 7.7265 4.39268 7.49984 4.66602 7.49984H9.33268C9.60602 7.49984 9.83268 7.7265 9.83268 7.99984C9.83268 8.27317 9.60602 8.49984 9.33268 8.49984Z"
        fill={color}
      />
    </svg>
  );
};
