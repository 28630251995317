import { WY_IMAGES } from "../../../../constants/images";
import { InfoIcon } from "../../../../assets/images/InfoIcon";
import { AppButton } from "../../../../components/AppButton/AppButton";
import { StyledSectionImage } from "../Sections.styles";
import { useNavigate } from "react-router-dom";
import { PAGES_ROUTES } from "../../../../constants/pages-routes";

export const ChatSection = () => {
  const navigate = useNavigate();

  return (
    <div style={{ position: "relative" }}>
      <StyledSectionImage src={WY_IMAGES.chatSection} />
      <AppButton
        buttonLevel="secondary"
        customStyles={{
          position: "absolute",
          bottom: 80,
          left: 100,
          alignSelf: "flex-start",
          width: 116,
          height: 32,
          fontSize: 12,
          backgroundColor: "black",
          display: "flex",
          flexDirection: "row",
          gap: 8,
          alignItems: "center",
          /* Media query for tablets */
          "@media (min-width: 768px) and (max-width: 1024px)": {
            bottom: 30,
            left: 60,
          },
        }}
        onClick={() => {
          navigate(PAGES_ROUTES.features.chats);
        }}
      >
        <InfoIcon /> Learn more
      </AppButton>
    </div>
  );
};
