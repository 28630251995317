import { Avatar } from "@mui/material";
import { CSSProperties } from "styled-components";

interface WYAvatarProps {
  imageSrc?: string;
  name?: string;
  styles?: CSSProperties;
}
export const AppAvatar = (props: WYAvatarProps) => {
  return (
    <Avatar sx={{ cursor: "pointer", ...props.styles }} src={props?.imageSrc}>
      {props?.name?.charAt(0)}
    </Avatar>
  );
};
