interface InfoIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const InfoIcon: React.FC<InfoIconProps> = ({
  color = "#AEB7BF",
  width = 18,
  height = 18,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1702 8.05555L15.1577 6.87055C14.9702 6.64555 14.8127 6.22555 14.8127 5.92555V4.65055C14.8127 3.85555 14.1602 3.20305 13.3652 3.20305H12.0902C11.7902 3.20305 11.3627 3.04555 11.1377 2.85805L9.95266 1.84555C9.43516 1.40305 8.58766 1.40305 8.07016 1.84555L6.87016 2.85805C6.64516 3.04555 6.22516 3.20305 5.92516 3.20305H4.62766C3.83266 3.20305 3.18016 3.85555 3.18016 4.65055V5.92555C3.18016 6.21805 3.03016 6.63805 2.84266 6.86305L1.83016 8.05555C1.39516 8.58055 1.39516 9.42055 1.83016 9.93055L2.84266 11.123C3.03016 11.3405 3.18016 11.768 3.18016 12.0605V13.343C3.18016 14.138 3.83266 14.7905 4.62766 14.7905H5.93266C6.22516 14.7905 6.65266 14.948 6.87766 15.1355L8.06266 16.148C8.58016 16.5905 9.42766 16.5905 9.94516 16.148L11.1302 15.1355C11.3552 14.948 11.7752 14.7905 12.0752 14.7905H13.3502C14.1452 14.7905 14.7977 14.138 14.7977 13.343V12.068C14.7977 11.768 14.9552 11.348 15.1427 11.123L16.1552 9.93805C16.6127 9.42805 16.6127 8.58055 16.1702 8.05555ZM8.43766 6.09805C8.43766 5.79055 8.69266 5.53555 9.00016 5.53555C9.30766 5.53555 9.56266 5.79055 9.56266 6.09805V9.72055C9.56266 10.028 9.30766 10.283 9.00016 10.283C8.69266 10.283 8.43766 10.028 8.43766 9.72055V6.09805ZM9.00016 12.653C8.58766 12.653 8.25016 12.3155 8.25016 11.903C8.25016 11.4905 8.58016 11.153 9.00016 11.153C9.41266 11.153 9.75016 11.4905 9.75016 11.903C9.75016 12.3155 9.42016 12.653 9.00016 12.653Z"
        fill={color}
      />
    </svg>
  );
};
