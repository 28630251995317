import AppTextField from "../AppTextField/AppTextField";
import { AppButton } from "../AppButton/AppButton";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import {
  StyledFooterContainer,
  StyledImageAndStayUpdatedContainer,
  StyledSocialMediaExploreCompanyContainer,
  StyledStayUpdated,
  StyledSubTitle,
  StyledSubTitleList,
  StyledTitle,
} from "./Footer.styles";
import { TITLES_SUBTITLES } from "./Footer.constants";
import { WY_GIFS } from "../../constants/images";
import { useNavigate } from "react-router-dom";
import { PAGES_ROUTES } from "../../constants/pages-routes";

export const Footer = () => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();

  return (
    <StyledFooterContainer>
      <StyledImageAndStayUpdatedContainer>
        <img
          src={WY_GIFS.weyanLogoGif}
          style={{ width: 100 }}
          alt="weyan Logo Gif"
        />
        {/* <StyledStayUpdated>
          Stay updated on all the latest!
          <AppTextField
            onChange={() => console.log("any word")}
            placeholder="What's your email?"
            styles={{
              height: 40,
              color: "white",
              width: screenWidth && screenWidth <= 600 ? 343 : 374,
            }}
            endAdornment={
              <AppButton
                customStyles={{
                  height: 32,
                  width: 83,
                  fontSize: 12,
                  color: "white",
                  border: "1px solid rgba(134, 143, 151, 0.4)",
                  background:
                    "linear-gradient(0deg, #0A0A0A, #0A0A0A), #000000",
                  "&:hover": {
                    backgroundColor: "unset",
                  },
                }}
                buttonLevel="secondary"
              >
                Subscribe
              </AppButton>
            }
          />
        </StyledStayUpdated> */}
      </StyledImageAndStayUpdatedContainer>

      <StyledSocialMediaExploreCompanyContainer>
        {TITLES_SUBTITLES.map((section, index) => (
          <div key={index}>
            <StyledTitle>{section.title}</StyledTitle>
            <StyledSubTitleList>
              {section.subTitles.map((subTitle, subIndex) => (
                <StyledSubTitle
                  onClick={() => navigate(subTitle.key)}
                  key={subIndex}
                >
                  {subTitle.value}
                </StyledSubTitle>
              ))}
            </StyledSubTitleList>
          </div>
        ))}
      </StyledSocialMediaExploreCompanyContainer>
    </StyledFooterContainer>
  );
};
