export let FAQ = [
  {
    title: "General Information",
    QA: [
      {
        question: "What is we.yan?",
        answer:
          "we.yan is a super app built inspired by Africa for Africans, everywhere, designed to integrate communication, financial transactions, and e-commerce into one platform.",
      },
      {
        question: "How does we.yan differ from other fintech platforms?",
        answer:
          "we.yan combines payments, chat, and marketplace functionalities in one app, making it a one-stop platform for users.",
      },
      {
        question: "What services does we.yan offer?",
        answer:
          "we.yan offers virtual wallets, chat, and marketplace functionalities, enabling users to send money, communicate, and shop securely.",
      },
      {
        question: "Is we.yan available outside of Africa?",
        answer:
          "Currently, we.yan is focused on the African market, but expansion plans may include international availability. You can get access to the chat from anywhere. Access to the wallet is dependent on having a local bank account (e.g., local bank account in Nigeria).",
      },
      {
        question: "How can I ensure my data is private on we.yan?",
        answer:
          "Ensure you use features like end-to-end encryption, encrypted backups, and two-step verification.",
      },
      {
        question: "Can I use we.yan on multiple devices simultaneously?",
        answer:
          "we.yan supports multiple devices, allowing you to switch between them while maintaining your data.",
      },
    ],
  },
  {
    title: "Account Setup and Security",
    QA: [],
  },
  {
    title: "Wallets and Payments",
    QA: [],
  },
  {
    title: "We.Yan Debit Cards",
    QA: [],
  },
  {
    title: "Membership Plans",
    QA: [],
  },
  {
    title: "Marketplace and Shop",
    QA: [],
  },
  {
    title: "Gifting and Donations",
    QA: [
      {
        question: "How can I send money as a gift to others on we.yan?",
        answer:
          "we.yan is a super app built inspired by Africa for Africans, everywhere, designed to integrate communication, financial transactions, and e-commerce into one platform.",
      },
      {
        question: "How can I make donations through we.yan?",
        answer:
          "we.yan combines payments, chat, and marketplace functionalities in one app, making it a one-stop platform for users.",
      },
      {
        question: "How can I create a charity wallet for donations on we.yan?",
        answer:
          "we.yan offers virtual wallets, chat, and marketplace functionalities, enabling users to send money, communicate, and shop securely.",
      },
      {
        question:
          "Is there a limit to how much I can gift or donate through we.yan?",
        answer:
          "Currently, we.yan is focused on the African market, but expansion plans may include international availability. You can get access to the chat from anywhere. Access to the wallet is dependent on having a local bank account (e.g., local bank account in Nigeria).",
      },
    ],
  },
  {
    title: "Chat and Communication",
    QA: [],
  },
  {
    title: "Privacy and Security",
    QA: [],
  },
  {
    title: "Technical Support & Assistance",
    QA: [],
  },
  {
    title: "Advanced Features and Customization",
    QA: [],
  },
  {
    title: "Investor Information",
    QA: [],
  },
  {
    title: "Corporate Information",
    QA: [],
  },
];
FAQ = FAQ.filter((section) => section.QA.length > 0);
