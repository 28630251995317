interface PasswordCheckIconProps {
  width?: number;
  height?: number;
}

export const PasswordCheckIcon: React.FC<PasswordCheckIconProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.66634H10.5V1.83301C10.5 1.55967 10.2733 1.33301 10 1.33301C9.72667 1.33301 9.5 1.55967 9.5 1.83301V14.1663C9.5 14.4397 9.72667 14.6663 10 14.6663C10.2733 14.6663 10.5 14.4397 10.5 14.1663V13.333H12C13.4733 13.333 14.6667 12.1397 14.6667 10.6663V5.33301C14.6667 3.85967 13.4733 2.66634 12 2.66634Z"
        fill="#8387A7"
      />
      <path
        d="M4.33268 2.66699C2.85935 2.66699 1.66602 3.86033 1.66602 5.33366V10.667C1.66602 12.1403 2.85935 13.3337 4.33268 13.3337H7.66602C8.03268 13.3337 8.33268 13.0337 8.33268 12.667V3.33366C8.33268 2.96699 8.03268 2.66699 7.66602 2.66699H4.33268ZM4.44602 8.25366C4.41268 8.33366 4.36602 8.40699 4.30602 8.47366C4.23935 8.53366 4.16602 8.58033 4.08602 8.61366C4.00602 8.64699 3.91935 8.66699 3.83268 8.66699C3.74602 8.66699 3.65935 8.64699 3.57935 8.61366C3.49935 8.58033 3.42602 8.53366 3.35935 8.47366C3.29935 8.40699 3.25268 8.33366 3.21268 8.25366C3.17935 8.17366 3.16602 8.08699 3.16602 8.00033C3.16602 7.82699 3.23935 7.65366 3.35935 7.52699C3.39268 7.50033 3.42602 7.47366 3.45935 7.44699C3.49935 7.42033 3.53935 7.40033 3.57935 7.38699C3.61935 7.36699 3.65935 7.35366 3.69935 7.34699C3.92602 7.30033 4.15268 7.37366 4.30602 7.52699C4.42602 7.65366 4.49935 7.82699 4.49935 8.00033C4.49935 8.08699 4.47935 8.17366 4.44602 8.25366ZM6.77935 8.25366C6.74602 8.33366 6.69935 8.40699 6.63935 8.47366C6.57268 8.53366 6.49935 8.58033 6.41935 8.61366C6.33935 8.64699 6.25268 8.66699 6.16602 8.66699C6.07935 8.66699 5.99268 8.64699 5.91268 8.61366C5.83268 8.58033 5.75935 8.53366 5.69268 8.47366C5.56602 8.34699 5.49935 8.18033 5.49935 8.00033C5.49935 7.91366 5.51935 7.82699 5.55268 7.74699C5.58602 7.66033 5.63268 7.59366 5.69268 7.52699C5.93935 7.28033 6.38602 7.28033 6.63935 7.52699C6.75935 7.65366 6.83268 7.82699 6.83268 8.00033C6.83268 8.08699 6.81268 8.17366 6.77935 8.25366Z"
        fill="#8387A7"
      />
    </svg>
  );
};
