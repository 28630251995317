import { CSSProperties } from "styled-components";
import {
  StyledContainer,
  StyledSmallTitle,
  StyledSubTitle,
  StyledTitle,
} from "./TitleSubtitleDescriptionComponent.styles";

export default function TitleSubtitleDescriptionComponent({
  smallTitle,
  titleSpan,
  titleSpanTwo,
  subTitle,
  IconComponent,
  containerStyle,
}: {
  smallTitle: React.ReactNode;
  titleSpan: React.ReactNode;
  titleSpanTwo?: React.ReactNode;
  subTitle?: React.ReactNode;
  containerStyle?: CSSProperties;
  IconComponent?: React.ComponentType | any;
}) {
  return (
    <StyledContainer style={{ ...containerStyle }}>
      <StyledSmallTitle>
        {IconComponent && <IconComponent />}
        {smallTitle}
      </StyledSmallTitle>
      <StyledTitle>
        <span>{titleSpan}</span> {titleSpanTwo}
      </StyledTitle>
      <StyledSubTitle>{subTitle}</StyledSubTitle>
    </StyledContainer>
  );
}
