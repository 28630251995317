import styled from "styled-components";

export const StyledMobileHomePageContainer = styled.div({
  // paddingTop: 20,
  paddingLeft: 20,
});
export const StyledStoresLinks = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  gap: 16,
});
export const StyledStoresLinksContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  border: "1px solid #868F9766",
  padding: 12,
  height: 40,
  gap: 10,
  borderRadius: 28,
});
export const StyledStoreLink = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
});
export const StyledTitleDescriptionContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  marginBottom: 40,
});

export const StyledPageDescription = styled.div({
  fontSize: 24,
  fontFamily: "GT Walsheim",
});

export const StyledGooglePlayAppStoreImg = styled.img({
  height: 40,
  width: 155,
});
export const StyledIphoneWalletImg = styled.img({
  height: 340,
  marginBottom: 100,
  width: "-webkit-fill-available",
});

export const StyledBanksFrameImg = styled.img({
  width: 345,
  height: 24,
});

export const StyledFeaturesImagesContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 0,
  margin: 0,
});

export const StyledFeaturesImages = styled.img({
  width: 400,
});

export const StyledMobileStakeholders = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  justifyItems: "center",
  alignItems: "center",
  marginTop: 195,
  padding: "0px 20px",
  textAlign: "center",
  zIndex: 2,
  position: "relative",
  transform: "translateX(-50%,-50%)",
});

export const StyledWorldMapMobileImage = styled.img({
  width: 400,
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1,
  opacity: 0.8,
});

export const StyledNeedsHelpImage = styled.img({
  display: "flex",
  justifySelf: "center",
  height: 1250,
});

export const StyledHeroImage = styled.img({
  width: "-webkit-fill-available",
  position: "absolute",
  top: -150,
});
export const StyledSectionsImages = styled.img({
  width: "100%", // Ensures the image takes the full width of its container
  maxWidth: "480px", // Set a reasonable max width for mobile devices
  height: "auto", // Keep the image ratio consistent
  margin: "0 auto", // Center the image
});
