import { WY_IMAGES } from "../../constants/images";

export const stakeHolders = [
  {
    name: "Tamer Bahgat",
    position: "CEO",
    flag2: WY_IMAGES.egyptFlag,
    flag: WY_IMAGES.nigeriaFlagZoomedOut,
    avatar: WY_IMAGES.stakeHolders.TamerBahgat,
  },
  {
    name: "Tarik Helmy",
    position: "CPO",
    flag: WY_IMAGES.egyptFlag,
    avatar: WY_IMAGES.stakeHolders.TarikHelmy,
  },
  {
    name: "Seye Olusoga",
    position: "Strategy",
    flag: WY_IMAGES.nigeriaFlagZoomedOut,
    avatar: WY_IMAGES.stakeHolders.SeyeOlusoga,
  },
  {
    name: "Lawrence Eta",
    position: "Growth",
    flag: WY_IMAGES.nigeriaFlagZoomedOut,
    avatar: WY_IMAGES.stakeHolders.LawrenceEta,
  },
  {
    name: "Nwamaka Udenigwe",
    position: "Growth",
    flag: WY_IMAGES.nigeriaFlagZoomedOut,
    avatar: WY_IMAGES.stakeHolders.NwamakaUdenigwe,
  },

  {
    name: "Bolanle Banwo",
    position: "Branding",
    flag: WY_IMAGES.nigeriaFlagZoomedOut,
    avatar: WY_IMAGES.stakeHolders.BolanleBanwo,
  },
  {
    name: "Tobi Olusoga",
    position: "Operations",
    flag: WY_IMAGES.nigeriaFlagZoomedOut,
    avatar: WY_IMAGES.stakeHolders.TobiOlusoga,
  },
  {
    name: "Mazen Ebeid",
    position: "CTO",
    flag: WY_IMAGES.egyptFlag,
    avatar: WY_IMAGES.stakeHolders.MazenEbeid,
  },
];
