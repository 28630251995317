import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { WY_COLORS } from "../../constants/app-colors";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState } from "react";
import { useLocation } from "react-router-dom";

interface AppMenuProps {
  title: string;
  children: React.ReactNode;
  menuStyles?: React.CSSProperties;
}

export default function AppMenu({ title, children, menuStyles }: AppMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          color: "inherit",
          "&:hover": {
            color: WY_COLORS.primaryBrightBlue300,
          },
        }}
      >
        {title}
      </Button>

      {anchorEl && (
        <div
          style={{
            position: "absolute",
            top: "19px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <ArrowDropUpIcon
            style={{
              fontSize: 50,
              color: "#565656",
            }}
          />
        </div>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          borderRadius: 10,
          opacity: 0.95,
          "& .MuiPaper-root": {
            backgroundColor: "#565656",
            borderRadius: 10,
            boxShadow: "none",
            marginTop: "10px",
            ...menuStyles,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div onClick={handleClose}>{children}</div>
      </Menu>
    </div>
  );
}
