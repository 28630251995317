import styled from "styled-components";

export const StyledHeaderContainer = styled.header({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "40px 16px",
  zIndex: 999999999,
});

export const StyledWeyanBlackBackgroundImage = styled.img({
  width: 50,
  cursor: "pointer",
});

export const StyledFeaturesContainer = styled.div({
  borderRight: "2px solid rgba(255, 255, 255, 0.08)",
  boxShadow: `-1px -1px 0px -0.5px rgba(134, 143, 151, 0.2) inset,
                1px 1px 0px -0.5px rgba(134, 143, 151, 0.2) inset,
                0px 0px 0px 0.5px rgba(134, 143, 151, 0.1) inset`,
  backdropFilter: "blur(20px)",
  borderRadius: 8,
  padding: "32px 42px 32px 32px",
  background: "#0C0C0C",
  display: "flex",
  flexDirection: "column",
  gap: 40,
});

export const StyledFeatureTitleIconDescription = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: 16,
});
export const StyledFeatureTitleDescription = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 8,
});
