interface EyeGlassesIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const EyeGlassesIcon: React.FC<EyeGlassesIconProps> = ({
  color = "#AEB7BF",
  width = 18,
  height = 8,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5625 2.3125H1.79533M17.4375 2.3125H16.2047M7.11613 2.17851C7.64387 1.79123 8.29522 1.5625 9 1.5625C9.70478 1.5625 10.3561 1.79123 10.8839 2.17851M7.11613 2.17851C6.54012 1.35276 5.58315 0.8125 4.5 0.8125C3.3593 0.8125 2.35855 1.41169 1.79533 2.3125M7.11613 2.17851C7.47628 2.69482 7.6875 3.32275 7.6875 4C7.6875 5.76041 6.26041 7.1875 4.5 7.1875C2.73959 7.1875 1.3125 5.76041 1.3125 4C1.3125 3.38029 1.48935 2.80188 1.79533 2.3125M10.8839 2.17851C10.5237 2.69482 10.3125 3.32275 10.3125 4C10.3125 5.76041 11.7396 7.1875 13.5 7.1875C15.2604 7.1875 16.6875 5.76041 16.6875 4C16.6875 3.38029 16.5107 2.80188 16.2047 2.3125M10.8839 2.17851C11.4599 1.35276 12.4168 0.8125 13.5 0.8125C14.6407 0.8125 15.6414 1.41169 16.2047 2.3125"
        stroke={color}
        stroke-width="1.35"
        stroke-linecap="round"
      />
    </svg>
  );
};
